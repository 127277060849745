import React from "react";

function Sunset() {
  return (
    <div style={{paddingLeft: 15}}>
      <h1>АзБуки.ML преустановява активното предлагане на услуги.</h1>
      <h3>
        АзБуки.ML предоставяше инструменти за обработка на
        българския език в периода 2020-2024.
      </h3>
      <h3>
        Част от данните и разработените инструменти, които са с отворен лиценз
        може да намерите в <a href="https://github.com/AzBuki-ML">GitHub</a>.
      </h3>
      <h3>Нови научни доклади от Радостин Чолаков в сферата на машинното обучение са достъпни в  <a href="https://scholar.google.com/citations?user=oYnCgH0AAAAJ&hl=en&oi=ao">Google Scholar</a>.</h3>
    </div>
  );
}

export default Sunset;
